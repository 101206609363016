import styled from "styled-components"

//customized css for message
export const SuccessMessage = styled.div`
color: white;
font-size: 16px;
font-family: "DM Sans";
font-weight: 400;
line-height: 24px;
`
