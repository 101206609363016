import { ArrowUpOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Progress, Space } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import CertificateDownloadModal from "../Modals/CertificationModal";
import useWindowSize from "../../hooks/useWindowSize";
import useBatchStore from "../../store/batchStore";

const CourseCompletionCard = ({ data, completed, bgColor, isStudentMigrated, batch }) => {
  const [viewCertificateOptions, setViewCertificateOptions] = useState(false);
  const [showCertificate, setShowCertificate] = useState(false);
  const [certificateData, setCertificateData] = useState({});
  const [percentage, setPercentage] = useState(0);
  const { width } = useWindowSize();
  const { generateMigrateCertificate } = useBatchStore();

  const { name, description, bannerImg } = data;
  const handleModal = (data) => {
    //console.log(data)
    setCertificateData(data?.studentCertificates?.length > 0 ? data?.studentCertificates[0] : {});
    setShowCertificate(true);
  };

  const calculatePercentage = () => {
    const percent = parseInt(parseInt(parseInt(data?.totalSectionProgress) / parseInt(data?.totalSectionItems)) * 100);
    let progress = parseInt(data?.totalSectionProgress) / parseInt(data?.totalSectionItems);
    progress = progress * 100;

    setPercentage(Math.min(parseInt(progress), 100));
  };

  const migrateCertificatesHandler = async () => {
    try {
      await generateMigrateCertificate(batch?.id);
    } catch (err) {}
  };

  const handleViewCertificateOptions = () => setViewCertificateOptions(!viewCertificateOptions);

  useEffect(() => {
    if (data) {
      calculatePercentage();
    }
  }, []);

  return (
    <>
      {/* open certificate modal for edit,download, and share */}
      <Modal
        open={showCertificate}
        onCancel={() => {
          setShowCertificate(false);
        }}
        footer={false}
        centered={true}
        width={width > 1200 ? 1097 : width >= 768 ? 700 : 397}
      >
        <CertificateDownloadModal data={certificateData} />
      </Modal>

      {(percentage < 100 || completed || isStudentMigrated) && (
        <Col span={24} md={12}>
          <StyledCard bgColor={bgColor} onGoing={Boolean(percentage < 100 || !completed)}>
            <MainCard width={width} completed={completed}>
              {isStudentMigrated && (
                <CustomButton onClick={migrateCertificatesHandler} type="default">
                  Get Certificate
                </CustomButton>
              )}
              <Space size={29}>
                <img src={bannerImg} className=" max-w-20 max-h-20" alt="icon" />
                <h4>{String(name).length > 30 ? String(name).substring(0, 30) + "..." : name}</h4>
              </Space>
              <hr />
              <div className="description-section">
                <p className="description">
                  {String(description)?.length > 60 ? String(description).substring(0, 60) + "..." : description}
                </p>
                {completed && (
                  <Button type="primary" onClick={handleViewCertificateOptions}>
                    View Certificate
                  </Button>
                )}
              </div>
              {!completed && (
                <div>
                  <div className="ongoing">
                    <p>Course Contents</p>
                    <p className="percentage-completion">{percentage}% Completed</p>
                  </div>
                  <Progress percent={percentage} showInfo={false} strokeColor={bgColor} />
                </div>
              )}
              {completed && (
                <HiddenCard show={viewCertificateOptions}>
                  <div className="top">
                    <p>{ data?.versions[0]?.certificates?.length } Certificates Earned 🎊</p>
                    <CustomHideButton onClick={handleViewCertificateOptions}>Hide Certificate</CustomHideButton>
                  </div>
                  <div className="certificate-list">
                    {data &&
                      data?.versions &&
                      data?.versions[0]?.certificates?.map((certificate, index) => (
                        <div className="completed" onClick={handleModal.bind(this, certificate)}>
                          <p>{certificate?.name}</p>
                          <i>
                            <ArrowUpOutlined rotate={45} />
                          </i>
                        </div>
                      ))}
                  </div>
                </HiddenCard>
              )}
            </MainCard>
          </StyledCard>
        </Col>
      )}
    </>
  );
};

const CustomButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 15px;
  /* width: fit-content; */
  /* margin-left: auto; */
`;

const CustomHideButton = styled.button`
  border-radius: 5px;
  padding: 4px 8px;
  flex-shrink: 0;
  background: var(--Color-White-100, #fff);
  color: #1e6de8;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 400;
  &:hover {
    background-color: #ebebeb;
  }
`;

const StyledCard = styled.div`
  font-feature-settings: "clig" off, "liga" off;
  font-family: "DM Sans";
  text-transform: capitalize;
  border-radius: 6.137px;
  box-shadow: 0px 0px 8.49px 0px rgba(151, 151, 151, 0.37);
  border-bottom-width: 7px;
  border-bottom-style: ${(props) => (props.onGoing ? "none" : "solid")};
  border-color: ${(props) => (props.bgColor ? props.bgColor : "#E7B11F")};
  max-width: 600px;
  //styling for the hidden div
  .ongoing {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 11px 0 3px 0;
    p {
      font-weight: 500;
    }
  }

  .percentage-completion {
    font-size: 14px;
    font-weight: 400;
  }
  .ant-progress-bg {
    opacity: 70%;
  }
`;

const MainCard = styled.div`
  display: flex;
  position: relative;
  justify-content: end;
  flex-direction: column;
  overflow-y: auto;
  padding: ${(props) => (props.width >= 768 ? " 0 16px 20px 16px" : "52px 16px 20px 16px")};
  height: ${(props) => (props.width >= 768 ? (props.completed ? "210px" : "240px") : null)};
  .ant-space {
    overflow-y: auto;
  }
  h4 {
    max-height: 115px;
    color: #171717;
    font-size: 22.912px;
    font-weight: 700;
  }
  hr {
    height: 0.511px;
    color: #5c8dbc;
    margin: 11px;
  }
  .description {
    color: var(--Neutral-Colors-800, #19213d);
    font-size: 14.729px;
    font-weight: 400;
    width: 300px;
  }
  .description-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const HiddenCard = styled.div`
  display: ${(props) => (props.show ? "flex" : "none")};
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 20px 16px;
  flex-direction: column;
  /* justify-content: space-between; */
  gap: 25px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;

  background-color: ${(props) => (props.bgColor ? props.bgColor : "#E7B11F")};
  color: white;

  .top {
    display: flex;
    width: 100%;
    justify-content: space-between;
    /* height: 38px; */
    align-items: center;
    align-self: stretch;
    color: var(--Color-White-100, #fff);
    font-feature-settings: "clig" off, "liga" off;
    font-family: "DM Sans";
    font-size: 20px;
    font-weight: 500;
  }
  .completed {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--Color-White-100, #fff);
    font-family: Inter;
    font-size: 14.4px;
    font-style: normal;
    font-weight: 600;
    line-height: 25.6px;
    letter-spacing: -0.144px;
    cursor: pointer;
  }
  .certificate-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }
  i {
    display: grid;
    place-items: center;
    background-color: #ffffff55;
    border-radius: 50%;
    height: 30px;
    width: 30px;
  }
`;
export default CourseCompletionCard;
