import styled from 'styled-components';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
const { Header, Content, Sider } = Layout;

export const StyledLayout = styled(Layout)`
height: 100vh;
overflow: hidden;
`


